(() => {
    const $resizeableIframes = document.querySelectorAll('.resizeable-iframe');

    const initResizeableIframes = () => {
        if (!$resizeableIframes) return;

        $resizeableIframes.forEach($resizeableIframe => {
            const initIframeResizer = () => {
                iframeResize({
                    license: 'GPLv3',
                    onMessage(messageData) {
                        if (messageData?.message?.type === 'updateUrl') {
                            if (messageData?.message?.data?.url) {
                                console.log(messageData?.message?.data?.url, window.location.href);
                                
                                window.history.pushState({
                                    iframeUrl: messageData?.message?.data?.title,
                                }, '', messageData.message.data.url);
                            }

                            if (messageData?.message?.data?.title) {
                                document.title = messageData.message.data.title;
                            }

                            return;
                        }
                    },
                }, $resizeableIframe);
            };

            initIframeResizer();
        });
    };

    initResizeableIframes();
})();
