import { closeModal } from '../common/modals';
import Cookies from 'js-cookie';

(() => {
    const $siteFooterBottomSpacer = document.querySelector('.site-footer-bottom-spacer');
    const $cookiesNotice = document.querySelector('.cookies-notice');
    const $cookiesModal = document.querySelector('.cookies-notice-options');
    const $acceptAllBtn = document.querySelector('.cookies-notice__accept-all-btn');
    const $rejectAllBtn = document.querySelector('.cookies-notice__reject-all-btn');
    const $cookiesForm = document.querySelector('.cookies-notice__form');

    if (!$cookiesNotice) return;

    const siteFooterCookiesNoticePadding = () => {
        if (!$siteFooterBottomSpacer) return;

        $siteFooterBottomSpacer.style.paddingBottom = `${$cookiesNotice?.classList?.contains('cookies-notice--hide') ? 0 : $cookiesNotice?.clientHeight}px`;
    };

    const initAnalyticalCode = cookieConsent => {
        let cookieConsentAccepted = cookieConsent ? cookieConsent : (Cookies.get('cookieConsent') ? JSON.parse(Cookies.get('cookieConsent'))?.analytical : false);
        
        if (GLOBALS?.GOOGLE_GA4_ID) {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });

            gtag('js', new Date());
            gtag('config', GLOBALS.GOOGLE_GA4_ID);
        }

        if (cookieConsentAccepted) {
            if (GLOBALS?.GOOGLE_GA4_ID) {
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
    
                gtag('consent', 'default', {
                    'analytics_storage': 'granted'
                });
            }

            if (GLOBALS?.LEAD_FORENSICS_ID) {
                const leadForensicsScript = document.createElement('script');
                leadForensicsScript.type = 'text/javascript';
                leadForensicsScript.src = `https://secure.lane5down.com/js/sc/${GLOBALS.LEAD_FORENSICS_ID}.js`;
                
                document.head.appendChild(leadForensicsScript);
            }

            if (GLOBALS?.HOTJAR_ID) {
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:GLOBALS?.HOTJAR_ID,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            }
        }
    }

    const acceptCookies = (allowNecessaryCookies, allowAnalyticalCookies, allowMarketingCookies) => {
        if (!allowNecessaryCookies) {
            Cookies.remove('cookieConsent');
            return;
        }

        Cookies.set('cookieConsent', JSON.stringify({ 
            necessary: allowNecessaryCookies ? true : false,
            analytical: allowAnalyticalCookies ? true : false,
            marketing: allowMarketingCookies ? true : false
        }), { expires: 365 });

        $cookiesNotice.classList.add('cookies-notice--hide');

        initAnalyticalCode(allowAnalyticalCookies ? true : false);

        setTimeout(() => {
            $cookiesNotice.parentNode.removeChild($cookiesNotice);
        }, 500);
    };

    const displayCookieConsentNotice = () => {
        if (window.scrollY === 0) return;

        const cookieConsent = Cookies.get('cookieConsent') ? JSON.parse(Cookies.get('cookieConsent')) : null;

        if (!cookieConsent || !cookieConsent.necessary) {
            $cookiesNotice.classList.remove('cookies-notice--hide');
            siteFooterCookiesNoticePadding();
        }

        window.removeEventListener('scroll', displayCookieConsentNotice);
    };

    if ($acceptAllBtn) {
        $acceptAllBtn.addEventListener('click', () => {
            acceptCookies(true, true, true);

            siteFooterCookiesNoticePadding();
        });
    }

    if ($rejectAllBtn) {
        $rejectAllBtn.addEventListener('click', () => {
            acceptCookies(true, false, false);

            siteFooterCookiesNoticePadding();
        });
    }

    if ($cookiesForm) {
        $cookiesForm.addEventListener('submit', e => {
            e.preventDefault();

            const necessaryCookies = e.target.querySelector('input[name="necessary-cookies"]');
            const analyticalCookies = e.target.querySelector('input[name="analytical-cookies"]');
            const marketingCookies = e.target.querySelector('input[name="marketing-cookies"]');

            acceptCookies(necessaryCookies?.checked, analyticalCookies?.checked, marketingCookies?.checked);

            if ($cookiesModal) {
                closeModal($cookiesModal);
            }

            siteFooterCookiesNoticePadding();
        });
    }

    window.addEventListener('resize', () => {
        siteFooterCookiesNoticePadding();
    });

    window.addEventListener('scroll', displayCookieConsentNotice);

    initAnalyticalCode();
})();
